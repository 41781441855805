<template>
  <div class="flex flex-wrap gap-3.5">
    <button
      v-for="provider in providers"
      :key="provider.id"
      type="button"
      class="inline-flex flex-[1_0_calc(33.333%-14px)] items-center justify-center gap-2 whitespace-nowrap rounded border border-[rgba(30,36,50,0.23)] px-5.5 py-2 text-base"
      @click="onAuthByProviderId(provider.id)"
    >
      <UiIcon :name="provider.icon" class="size-6 flex-shrink-0" skip-svgo />
      <span>{{ $t("continueWith", { title: provider.name }) }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(["success", "failed"]);

const generalStore = useGeneralStore();
const generalInternalStore = useGeneralInternalStore();
const { authByProviderId } = useAuth();

const providers = [
  { id: "google.com", name: "Google", icon: "social-logo/google" },
  { id: "facebook.com", name: "Facebook", icon: "social-logo/facebook" },
  // { id: "apple.com", name: "Apple", icon: "social-logo/apple" },
] as const;

type ProviderIds = (typeof providers)[number]["id"];

async function onAuthByProviderId(providerId: ProviderIds) {
  try {
    await authByProviderId(providerId);
    generalInternalStore.isSignInDrawerOpen = false;
    await Promise.all([generalStore.getCart(), generalStore.getWishList()]);
    emit("success");
  } catch (error) {
    emit("failed");
  }
}
</script>

<style>
.social-auth {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.social-auth--button {
  display: inline-flex;
  padding: 8px 22px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid rgba(30, 36, 50, 0.23);
  font-size: 16px;
  white-space: nowrap;
  flex: 1 0 calc(33.333% - 14px);
}

.social-auth--button-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
</style>
